// Generated by Framer (2add0ca)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, getPropertyControls, Link, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Material } from "https://framerusercontent.com/modules/6Ldpz1V0DkD45gXvi67I/PCgBX5d6MdQT7E7nhdXn/Material.js";
const MaterialFonts = getFonts(Material);
const MaterialControls = getPropertyControls(Material);

const cycleOrder = ["GnosBmAub"];

const variantClassNames = {GnosBmAub: "framer-v-opqif5"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; name?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "GnosBmAub", name: lo5DP8UKK = "Twitter", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "GnosBmAub", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-T8kvK", classNames)} style={{display: "contents"}}>
<Transition value={transition}><Link href={"https://twitter.com/Tanjim38"}><motion.a {...restProps} className={`${cx("framer-opqif5", className)} framer-bxn42a`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"GnosBmAub"} ref={ref} style={{...style}}><motion.div className={"framer-tmtk2-container"} layoutDependency={layoutDependency} layoutId={"Ux3TCE8Ib-container"}><Material color={"var(--token-883132e0-45d6-4b2b-bb30-29dc404e311e, rgb(29, 29, 31)) /* {\"name\":\"text color\"} */"} height={"100%"} iconSearch={"Home"} iconSelection={lo5DP8UKK} iconStyle15={"Filled"} iconStyle2={"Filled"} iconStyle7={"Filled"} id={"Ux3TCE8Ib"} layoutId={"Ux3TCE8Ib"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div></motion.a></Link></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-T8kvK [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-T8kvK .framer-bxn42a { display: block; }", ".framer-T8kvK .framer-opqif5 { height: 24px; overflow: hidden; position: relative; text-decoration: none; width: 24px; }", ".framer-T8kvK .framer-tmtk2-container { bottom: 0px; flex: none; left: 0px; position: absolute; right: 0px; top: 0px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 24
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"lo5DP8UKK":"name"}
 */
const FramerQQnjEQLKZ: React.ComponentType<Props> = withCSS(Component, css, "framer-T8kvK") as typeof Component;
export default FramerQQnjEQLKZ;

FramerQQnjEQLKZ.displayName = "Twitter Icon";

FramerQQnjEQLKZ.defaultProps = {height: 24, width: 24};

addPropertyControls(FramerQQnjEQLKZ, {lo5DP8UKK: MaterialControls?.["iconSelection"] && {...MaterialControls["iconSelection"], defaultValue: "Twitter", hidden: undefined, title: "Name"}} as any)

addFonts(FramerQQnjEQLKZ, [...MaterialFonts])